@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~antd/dist/antd.less';

.login-page {
  .ant-form-item-control {
    display: contents!important;
  }
}

.tab-table-with-round-corner {
  .ant-tabs-tab {
    border-radius: 0px 15px 0px 0px!important;
  }
  .ant-tabs-nav {
    margin: 0!important;
  }
  .ant-card-body {
    padding: 0px!important;
  }
}

.tab-setting-game {
  margin-top: -65px;
  margin-right: -12px;
  .ant-tabs-nav-wrap {
    justify-content: end;
  }
  .ant-tabs-tab {
    border-radius: 15px 0px 0px 0px!important;
  }
  .ant-tabs-nav {
    margin: 0!important;
  }
  .ant-card-body {
    padding: 0px!important;
  }
}

.hover-pointer {
  cursor: pointer;
}

.expand-parent {
  background-color: #f44336;
}
@primary-color: #D61921;@link-color: #D61921;@success-color: #1DA57A;@warning-color: #D61921;@border-radius-base: 4px;